import { Container, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { Section } from 'components/@screens';

const Practical = () => {
  return (
    <Section name="praktisch">
      <Container
        maxWidth="lg"
        sx={{
          py: 8,
        }}
      >
        <Typography variant="h1" color="global01" mb={6}>
          <FormattedMessage id="home.practical.title" />
        </Typography>
        <Typography
          variant="h3"
          component="h2"
          color="global01"
          mb={2}
          maxWidth={700}
        >
          <FormattedMessage id="home.practical.item.1.title" />
        </Typography>
        <Typography variant="body1" maxWidth={700} mb={6}>
          <FormattedMessage id="home.practical.item.1.body" />
        </Typography>
        <Typography
          variant="h3"
          component="h2"
          color="global01"
          mb={2}
          maxWidth={700}
        >
          <FormattedMessage id="home.practical.item.2.title" />
        </Typography>
        <Typography variant="body1" maxWidth={700}>
          <FormattedMessage id="home.practical.item.2.body" />
        </Typography>
      </Container>
    </Section>
  );
};

export default Practical;
