import { Container, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const Footer = () => {
  return (
    <Container
      component="footer"
      maxWidth="lg"
      sx={{
        pt: 8,
        pb: 2,
      }}
    >
      <Typography>
        <FormattedMessage id="footer.copyright" />
      </Typography>
    </Container>
  );
};

export default Footer;
