import posthog from 'posthog-js';
import { useEffect, useRef } from 'react';

import { initPosthog } from 'services/posthog';

import {
  Appointment,
  Info,
  IntroHeader,
  Practical,
  Specialisations,
  Usp,
} from 'components/@home';
import { Footer, NavigationHeader } from 'components/@navigation';

initPosthog();

const Home = () => {
  const didTrackPage = useRef(false);

  useEffect(() => {
    if (!didTrackPage.current) {
      didTrackPage.current = true;
      posthog.capture('home_view');
    }
  }, []);

  return (
    <>
      <NavigationHeader />
      <main>
        <IntroHeader />
        <Usp />
        <Info />
        <Specialisations />
        <Appointment />
        <Practical />
      </main>
      <Footer />
    </>
  );
};

export default Home;
