import { Box, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { ReactNode } from 'react';

interface Props {
  icon: ReactNode;
  title: ReactNode;
  body: ReactNode;
}
const UspItem = ({ icon, title, body }: Props) => {
  return (
    <Stack gap={2}>
      <Box fontSize={85}>{icon}</Box>
      <Typography variant="h3" component="h2" color="global01">
        {title}
      </Typography>
      <Typography variant="body1">{body}</Typography>
    </Stack>
  );
};

export default UspItem;
