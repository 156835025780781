import { Box } from '@mui/material';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  bgcolor?: string;
  name?: string | string[];
}

const Section = ({ children, bgcolor, name }: Props) => {
  return (
    <>
      {name && (
        <>
          {typeof name === 'string' ? (
            <Box id={name} visibility="hidden" mt="-74px" position="absolute" />
          ) : (
            name.map((n) => (
              <Box
                key={n}
                id={n}
                visibility="hidden"
                mt="-74px"
                position="absolute"
              />
            ))
          )}
        </>
      )}
      <Box component="section" bgcolor={bgcolor ?? 'white'} overflow="hidden">
        {children}
      </Box>
    </>
  );
};

export default Section;
