import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoBlueEllipse = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 111 111"
    fill="none"
    role="img"
    {...props}
  >
    <path
      d="M110.5 111C95.9889 111 81.62 108.142 68.2135 102.589C54.807 97.0355 42.6256 88.8962 32.3647 78.6353C22.1038 68.3744 13.9645 56.193 8.41132 42.7865C2.85818 29.38 9.79438e-06 15.0111 1.04287e-05 0.49999L110.5 0.500003L110.5 111Z"
      fill="#0066FE"
      fillOpacity={0.2}
    />
  </svg>
);
const Memo = memo(SvgIcoBlueEllipse);
export default Memo;
