import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoBack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 100 101"
    fill="none"
    role="img"
    {...props}
  >
    <g clipPath="url(#clip0_64_1907)">
      <path
        d="M9.07157 94.1422C9.06224 83.7677 11.0964 73.4931 15.0579 63.9048C19.0194 54.3165 24.8306 45.6024 32.1599 38.2599C39.4891 30.9175 48.1928 25.0906 57.7739 21.1118C67.355 17.1331 77.626 15.0805 88.0005 15.0711L88.0254 42.8127C81.2941 42.8188 74.6298 44.1506 68.4132 46.7322C62.1966 49.3138 56.5493 53.0945 51.7938 57.8586C47.0383 62.6226 43.2677 68.2767 40.6973 74.498C38.127 80.7192 36.8071 87.3859 36.8132 94.1172L9.07157 94.1422Z"
        fill="#FF6565"
        fillOpacity={0.2}
      />
      <path
        d="M39.0626 7.23047V11.8789L37.6564 12.0156C33.6134 12.3867 29.8829 14.1055 26.9923 16.918C26.2892 17.6211 25.5275 18.1875 25.3322 18.1875C24.1212 18.1875 20.5275 18.8906 18.8087 19.457C14.8243 20.7852 12.0314 22.5234 8.96497 25.5898C5.89856 28.6562 4.16028 31.4492 2.83216 35.4336C1.52356 39.3594 1.56262 38.4414 1.56262 70.5117V99.4375H3.12512H4.68762V70.5703C4.68762 52.5039 4.76575 41.2148 4.88294 40.3555C5.74231 34.3984 8.78919 29.2227 13.5743 25.6289C16.9337 23.0898 22.0704 21.332 26.1329 21.3125C27.1681 21.3125 27.1876 21.3125 28.4767 19.9258C31.5822 16.6055 35.1173 15.0625 39.5704 15.0625C41.0743 15.0625 41.2697 15.0234 41.6993 14.5742L42.1876 14.1055V8.32422V2.5625H40.6251H39.0626V7.23047Z"
        fill="#233B76"
      />
      <path
        d="M57.8125 8.32422V14.1055L58.3008 14.5742C58.7305 15.0234 58.9258 15.0625 60.4297 15.0625C64.8438 15.0625 68.3203 16.5469 71.3672 19.7695L72.832 21.3125H73.8672C76.5234 21.332 79.9805 22.1328 82.5781 23.3633C85.1367 24.5742 86.9531 25.8438 88.8672 27.7578C92.2852 31.1953 94.4141 35.4727 95.1172 40.3555C95.2344 41.2148 95.3125 52.5039 95.3125 70.5703V99.4375H96.875H98.4375V70.5117C98.4375 38.4609 98.4766 39.4375 97.168 35.4727C95.8984 31.5664 94.043 28.5977 91.0352 25.5898C88.0469 22.582 85.1172 20.7656 81.1914 19.457C79.4727 18.8906 75.8789 18.1875 74.668 18.1875C74.4727 18.1875 73.7109 17.6211 73.0078 16.918C70.0977 14.0859 66.4062 12.3867 62.4023 12.0156L60.9375 11.8789V7.23047V2.5625H59.375H57.8125V8.32422Z"
        fill="#233B76"
      />
      <path
        d="M45.8398 15.4922C45.5859 15.7461 45.293 16.4297 45.1367 17.0547L44.9023 18.1875H43.5352H42.1875V19.75V21.3125H43.1445H44.1211L43.9258 21.9375C43.6523 22.9531 43.7109 23.4414 44.2383 23.9492L44.707 24.4375H50H55.293L55.7617 23.9492C56.2891 23.4414 56.3477 22.9531 56.0742 21.9375L55.8789 21.3125H56.8555H57.8125V19.75V18.1875H56.4648H55.0977L54.8633 17.0547C54.4141 15.082 54.3555 15.0625 50 15.0625C46.3867 15.0625 46.25 15.082 45.8398 15.4922ZM52.3047 19.75L52.6953 21.3125H50H47.3047L47.6953 19.75L48.0859 18.1875H50H51.9141L52.3047 19.75Z"
        fill="#233B76"
      />
      <path
        d="M45.8398 27.9922C45.5859 28.2461 45.293 28.9297 45.1367 29.5547L44.9023 30.6875H43.5352H42.1875V32.25V33.8125H43.1445H44.1211L43.9258 34.4375C43.6523 35.4531 43.7109 35.9414 44.2383 36.4492L44.707 36.9375H50H55.293L55.7617 36.4492C56.2891 35.9414 56.3477 35.4531 56.0742 34.4375L55.8789 33.8125H56.8555H57.8125V32.25V30.6875H56.4648H55.0977L54.8633 29.5547C54.4141 27.582 54.3555 27.5625 50 27.5625C46.3867 27.5625 46.25 27.582 45.8398 27.9922ZM52.3047 32.25L52.6953 33.8125H50H47.3047L47.6953 32.25L48.0859 30.6875H50H51.9141L52.3047 32.25Z"
        fill="#233B76"
      />
      <path
        d="M34.375 33.9102L33.3008 34.9844L36.3281 38.0117C37.9883 39.6719 39.4336 41.0391 39.5508 41.0391C39.8047 41.0391 41.6016 39.2422 41.6016 38.9883C41.6016 38.793 35.7227 32.8359 35.5469 32.8359C35.4883 32.8359 34.9609 33.3242 34.375 33.9102Z"
        fill="#FF6565"
      />
      <path
        d="M61.3281 35.8633L58.3008 38.8906L59.4336 40.0039L60.5469 41.1367L63.5742 38.1094C65.2344 36.4492 66.6016 35.0039 66.6016 34.8867C66.6016 34.6328 64.8047 32.8359 64.5508 32.8359C64.4336 32.8359 62.9883 34.2031 61.3281 35.8633Z"
        fill="#FF6565"
      />
      <path
        d="M45.8398 40.4922C45.5859 40.7461 45.293 41.4297 45.1367 42.0547L44.9023 43.1875H43.5352H42.1875V44.75V46.3125H43.1445H44.1211L43.9258 46.9375C43.6523 47.9531 43.7109 48.4414 44.2383 48.9492L44.707 49.4375H50H55.293L55.7617 48.9492C56.2891 48.4414 56.3477 47.9531 56.0742 46.9375L55.8789 46.3125H56.8555H57.8125V44.75V43.1875H56.4648H55.0977L54.8633 42.0547C54.4141 40.082 54.3555 40.0625 50 40.0625C46.3867 40.0625 46.25 40.082 45.8398 40.4922ZM52.3047 44.75L52.6953 46.3125H50H47.3047L47.6953 44.75L48.0859 43.1875H50H51.9141L52.3047 44.75Z"
        fill="#FF6565"
      />
      <path
        d="M31.25 44.75V46.3125H35.1562H39.0625V44.75V43.1875H35.1562H31.25V44.75Z"
        fill="#FF6565"
      />
      <path
        d="M60.9375 44.75V46.3125H64.8438H68.75V44.75V43.1875H64.8438H60.9375V44.75Z"
        fill="#FF6565"
      />
      <path
        d="M36.3281 51.4883L33.3008 54.5156L34.4336 55.6289L35.5469 56.7617L38.5742 53.7344C40.2344 52.0742 41.6016 50.6289 41.6016 50.5117C41.6016 50.2578 39.8047 48.4609 39.5508 48.4609C39.4336 48.4609 37.9883 49.8281 36.3281 51.4883Z"
        fill="#FF6565"
      />
      <path
        d="M59.375 49.5352L58.3008 50.6094L61.3281 53.6367C62.9883 55.2969 64.4336 56.6641 64.5508 56.6641C64.8047 56.6641 66.6016 54.8672 66.6016 54.6133C66.6016 54.418 60.7227 48.4609 60.5469 48.4609C60.4883 48.4609 59.9609 48.9492 59.375 49.5352Z"
        fill="#FF6565"
      />
      <path
        d="M16.9531 51.0586C16.3281 51.293 15.8203 51.5469 15.8203 51.625C15.8203 51.7031 16.7969 54.3789 17.9883 57.582L20.1758 63.3633L19.7266 64.3594C18.0664 68.2266 16.9141 72.0352 16.1914 76.1953C15.7812 78.4414 15.7422 79.4375 15.6836 89.0273L15.5859 99.4375H17.1484H18.7109L18.8086 89.0273C18.9062 77.8359 18.9648 76.9766 20.1562 72.6016L20.5664 71.0781L21.1914 72.8945C21.543 73.9102 22.0703 75.2773 22.3633 75.9609C23.3789 78.3828 23.4375 79.0078 23.4375 89.6133V99.4375H25.0195H26.582L26.5234 88.8516C26.4453 78.5391 26.4258 78.207 26.0156 76.8203C25.7812 76.0195 25.3125 74.8086 25 74.1055C23.75 71.3711 23.5742 70.5117 23.4961 66.7227L23.418 63.207L21.0547 56.8984C19.2578 52.1133 18.6328 50.6094 18.3789 50.6289C18.2031 50.6289 17.5586 50.8242 16.9531 51.0586Z"
        fill="#233B76"
      />
      <path
        d="M78.9453 56.8984L76.582 63.207L76.5039 66.7227C76.4258 70.4727 76.1719 71.7422 75.0781 73.8906C74.8242 74.3984 74.375 75.5703 74.082 76.5273L73.5547 78.2461L73.4766 88.8516L73.418 99.4375H74.9805H76.5625V89.6133C76.5625 79.0078 76.6211 78.3828 77.6367 75.9609C77.9297 75.2773 78.457 73.9102 78.8086 72.8945L79.4336 71.0781L79.8438 72.6016C81.0352 77.0742 81.25 79.8086 81.25 90.4727V99.4375H82.832H84.4141L84.3164 89.1445C84.2578 80.5703 84.1797 78.4805 83.9062 76.7812C83.2031 72.543 82.0117 68.4023 80.5469 65.043C80.1562 64.1641 79.8828 63.3047 79.9414 63.1289C79.9805 62.9531 80.957 60.3359 82.1094 57.2891C83.2422 54.2617 84.1797 51.7031 84.1797 51.625C84.1797 51.4883 81.9141 50.6094 81.5234 50.6094C81.4062 50.6094 80.2344 53.4414 78.9453 56.8984Z"
        fill="#233B76"
      />
      <path
        d="M45.8398 52.9922C45.5859 53.2461 45.293 53.9297 45.1367 54.5547L44.9023 55.6875H43.5352H42.1875V57.25V58.8125H43.1445H44.1211L43.9258 59.4375C43.6523 60.4531 43.7109 60.9414 44.2383 61.4492L44.707 61.9375H50H55.293L55.7617 61.4492C56.2891 60.9414 56.3477 60.4531 56.0742 59.4375L55.8789 58.8125H56.8555H57.8125V57.25V55.6875H56.4648H55.0977L54.8633 54.5547C54.4141 52.582 54.3555 52.5625 50 52.5625C46.3867 52.5625 46.25 52.582 45.8398 52.9922ZM52.3047 57.25L52.6953 58.8125H50H47.3047L47.6953 57.25L48.0859 55.6875H50H51.9141L52.3047 57.25Z"
        fill="#233B76"
      />
      <path
        d="M44.2383 65.5508C43.8281 65.9414 43.75 66.2148 43.75 67.1133V68.1875H42.1875H40.625V69.75V71.3125H42.1875H43.75V72.3867C43.75 73.2852 43.8281 73.5586 44.2383 73.9492L44.707 74.4375H50H55.293L55.7617 73.9492C56.1719 73.5586 56.25 73.2852 56.25 72.3867V71.3125H57.8125H59.375V69.75V68.1875H57.8125H56.25V67.1133C56.25 66.2148 56.1719 65.9414 55.7617 65.5508L55.293 65.0625H50H44.707L44.2383 65.5508ZM53.125 69.75V71.3125H50H46.875V69.75V68.1875H50H53.125V69.75Z"
        fill="#233B76"
      />
      <path
        d="M44.2383 78.0508C43.8281 78.4414 43.75 78.7148 43.75 79.6133V80.6875H42.1875H40.625V82.25V83.8125H42.1875H43.75V84.8867C43.75 85.7852 43.8281 86.0586 44.2383 86.4492L44.707 86.9375H50H55.293L55.7617 86.4492C56.1719 86.0586 56.25 85.7852 56.25 84.8867V83.8125H57.8125H59.375V82.25V80.6875H57.8125H56.25V79.6133C56.25 78.7148 56.1719 78.4414 55.7617 78.0508L55.293 77.5625H50H44.707L44.2383 78.0508ZM53.125 82.25V83.8125H50H46.875V82.25V80.6875H50H53.125V82.25Z"
        fill="#233B76"
      />
      <path
        d="M44.2383 90.5508C43.8281 90.9414 43.75 91.2148 43.75 92.1133V93.1875H42.1875H40.625V94.75V96.3125H42.1875H43.75V97.3867C43.75 98.2852 43.8281 98.5586 44.2383 98.9492L44.707 99.4375H50H55.293L55.7617 98.9492C56.1719 98.5586 56.25 98.2852 56.25 97.3867V96.3125H57.8125H59.375V94.75V93.1875H57.8125H56.25V92.1133C56.25 91.2148 56.1719 90.9414 55.7617 90.5508L55.293 90.0625H50H44.707L44.2383 90.5508ZM53.125 94.75V96.3125H50H46.875V94.75V93.1875H50H53.125V94.75Z"
        fill="#233B76"
      />
    </g>
    <defs>
      <clipPath id="clip0_64_1907">
        <rect width={100} height={101} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgIcoBack);
export default Memo;
