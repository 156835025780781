import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoGreenCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 156 152"
    fill="none"
    role="img"
    {...props}
  >
    <path
      d="M155.335 1.17432C155.03 21.1986 150.784 40.9667 142.84 59.3501C134.895 77.7334 123.408 94.372 109.033 108.316C94.6578 122.259 77.6773 133.235 59.0606 140.617C40.4439 147.998 20.5557 151.64 0.531428 151.335L1.19169 107.967C15.5207 108.185 29.7524 105.579 43.0742 100.297C56.396 95.0147 68.5469 87.1605 78.8333 77.1826C89.1198 67.2047 97.3401 55.2985 103.025 42.1437C108.71 28.9888 111.749 14.8431 111.967 0.514076L155.335 1.17432Z"
      fill="#12AA2B"
      fillOpacity={0.2}
    />
  </svg>
);
const Memo = memo(SvgIcoGreenCircle);
export default Memo;
