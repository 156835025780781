import { Box, Container, Grid, Typography } from '@mui/material';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import {
  IcoBaby,
  IcoBack,
  IcoBreath,
  IcoCrystals,
  IcoGeneralTherapy,
  IcoManualTherapy,
  IcoSports,
} from 'components/@icons';
import { Section } from 'components/@screens';

const ITEMS = [
  {
    icon: <IcoGeneralTherapy aria-label="Icoon algemene kinesitherapie" />,
    title: 'home.specialisations.general.title',
    body: 'home.specialisations.general.body',
  },
  {
    icon: <IcoManualTherapy aria-label="Icoon manuele therapie" />,
    title: 'home.specialisations.manual_therapy.title',
    body: 'home.specialisations.manual_therapy.body',
  },
  {
    icon: <IcoBreath aria-label="Icoon ademhaling" />,
    title: 'home.specialisations.breath.title',
    body: 'home.specialisations.breath.body',
  },
  {
    icon: <IcoBaby aria-label="Icoon perinatale" />,
    title: 'home.specialisations.perinatale.title',
    body: 'home.specialisations.perinatale.body',
  },
  {
    icon: <IcoSports aria-label="Icoon sport revalidatie" />,
    title: 'home.specialisations.sport_revalidation.title',
    body: 'home.specialisations.sport_revalidation.body',
  },
  {
    icon: <IcoCrystals aria-label="Icoon vestibulaire therapie" />,
    title: 'home.specialisations.vestibulaire.title',
    body: 'home.specialisations.vestibulaire.body',
  },
  {
    icon: <IcoBack aria-label="Icoon rugscholing" />,
    title: 'home.specialisations.back_school.title',
    body: 'home.specialisations.back_school.body',
  },
];

const Specialisations = () => {
  return (
    <Section name="specialisaties">
      <Container
        maxWidth="lg"
        sx={{
          py: 8,
        }}
      >
        <Typography variant="h1" color="global01" mb={6}>
          <FormattedMessage id="home.specialisations.title" />
        </Typography>
        <Grid container rowGap={4} columnSpacing={3}>
          {ITEMS.map((item) => (
            <Grid key={item.title} item xs={12} md={6}>
              <Box fontSize={{ xs: 75, md: 100 }}>{item.icon}</Box>
              <Typography variant="h2" color="global01" mt={1} mb={2}>
                <FormattedMessage id={item.title as MessageDescriptor['id']} />
              </Typography>
              <Typography variant="body1" mb={2}>
                <FormattedMessage id={item.body as MessageDescriptor['id']} />
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
};

export default Specialisations;
