import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoBill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 85 85"
    fill="none"
    role="img"
    {...props}
  >
    <g clipPath="url(#clip0_76_1842)">
      <path
        d="M25.1695 84.2282C18.4937 76.567 15.1348 66.5677 15.8317 56.43C16.5285 46.2923 21.224 36.8466 28.8852 30.1709C36.5463 23.4952 46.5456 20.1363 56.6834 20.8331C66.8211 21.5299 76.2668 26.2254 82.9425 33.8866L69.8617 45.2847C66.209 41.0928 61.0406 38.5236 55.4936 38.1423C49.9466 37.761 44.4753 39.5989 40.2833 43.2516C36.0914 46.9044 33.5222 52.0727 33.1409 57.6198C32.7596 63.1668 34.5975 68.6381 38.2502 72.83L25.1695 84.2282Z"
        fill="#FF6565"
        fillOpacity={0.2}
      />
      <g clipPath="url(#clip1_76_1842)">
        <path
          d="M5.81484 6.20508C5.62344 6.31446 5.39102 6.57422 5.29531 6.7793C5.18594 7.06641 5.14492 15.0371 5.17227 38.1016L5.21328 69.0547L5.59609 69.3828L5.97891 69.7109H24.4633H42.9477L43.6996 70.6816C45.5043 73.0195 48.2797 74.7832 51.4379 75.6035C52.4633 75.877 52.9828 75.918 54.7738 75.918C56.6195 75.9043 57.0707 75.8633 58.2602 75.5488C63.3187 74.1953 67.2016 70.2305 68.473 65.1035C68.8969 63.4082 68.8969 60.3047 68.4867 58.6367C67.6117 55.1504 65.4516 52.0605 62.5531 50.1191C60.8168 48.9707 58.643 48.1094 56.934 47.9043L56.141 47.8086V32.6602C56.141 24.334 56.1 17.3066 56.059 17.0606C55.977 16.6777 55.0473 15.666 50.891 11.4961C48.102 8.67969 45.6547 6.30079 45.4496 6.19141C44.9027 5.9043 6.34805 5.91797 5.81484 6.20508ZM43.4262 13.2188C43.4262 17.1426 43.4535 17.7305 43.6449 18.0176C44.1098 18.6738 44.4652 18.7148 49.1 18.7148H53.4066L53.3793 33.2207L53.3383 47.7402L52.1488 47.9863C50.5629 48.3008 49.7426 48.5879 48.348 49.2715C44.4652 51.2129 41.7445 54.8086 40.8148 59.252C40.7191 59.7031 40.6371 60.8379 40.6234 61.7813C40.6234 63.5313 40.8148 64.8301 41.266 66.1426C41.4027 66.5527 41.5121 66.9082 41.5121 66.9355C41.5121 66.9492 33.9516 66.9766 24.6957 66.9766H7.8793V37.8555V8.73438H25.6527H43.4262V13.2188ZM48.8266 13.3828L51.4242 15.9805H48.7855H46.1605V13.3828C46.1605 11.9473 46.1742 10.7852 46.2016 10.7852C46.2152 10.7852 47.4047 11.9473 48.8266 13.3828ZM56.6195 50.6387C59.1352 51.0762 61.227 52.2246 63.0043 54.1113C64.9457 56.1895 65.9027 58.3906 66.0805 61.2344C66.4223 66.6074 62.7855 71.5293 57.4398 72.9375C56.1547 73.2793 53.2973 73.2793 52.0531 72.9512C49.9613 72.3906 48.5258 71.5977 47.0219 70.2031C45.8324 69.0957 44.9574 67.9063 44.3832 66.6621C42.3324 62.1367 43.3168 57.0098 46.8852 53.6191C49.5512 51.0898 53.0512 50.0234 56.6195 50.6387Z"
          fill="#233B76"
        />
        <path
          d="M26.6777 16.5137C26.5273 16.582 26.2812 16.7734 26.1309 16.9375C25.8848 17.2109 25.8438 17.4844 25.7891 19.4531L25.7207 21.6543L23.5059 21.6953L21.291 21.7227L20.9492 22.1465L20.5938 22.5703V26.5352V30.5L20.9902 30.8965L21.3867 31.293H23.5879H25.7891V33.4941V35.6953L26.1855 36.0918L26.582 36.4883H30.5742C34.9629 36.4883 35.0586 36.4746 35.3457 35.6406C35.4277 35.4082 35.4961 34.3281 35.4961 33.2617V31.293H37.5469C39.8027 31.293 40.2402 31.1836 40.5273 30.541C40.6367 30.2949 40.6914 29.0645 40.6914 26.4941C40.6914 22.584 40.6367 22.2012 40.0215 21.8594C39.8711 21.7773 38.8184 21.7227 37.6289 21.7227H35.4961V19.7402C35.4961 17.3887 35.3594 16.8965 34.6211 16.5957C34.2383 16.4316 33.4043 16.3906 30.5469 16.4043C28.5645 16.4043 26.8281 16.459 26.6777 16.5137ZM32.7617 21.4082C32.8301 23.6094 32.8301 23.6367 33.2129 24.0059C33.582 24.3887 33.5957 24.3887 35.7695 24.4434L37.957 24.4844V26.5215V28.5586H35.9062C33.7324 28.5586 33.1992 28.6816 32.8984 29.2285C32.8164 29.3789 32.7617 30.4316 32.7617 31.6211V33.7539H30.6562H28.5371L28.4961 31.5664C28.4551 29.3926 28.4551 29.3789 28.0723 29.0098C27.7031 28.627 27.6895 28.627 25.5156 28.5859L23.3281 28.5449V26.5078V24.4844L25.4199 24.4297C27.7031 24.3887 28.127 24.252 28.3867 23.5547C28.4688 23.3633 28.5234 22.2832 28.5234 21.1621V19.1113L30.6152 19.1523L32.6934 19.1934L32.7617 21.4082Z"
          fill="#FF6565"
        />
        <path
          d="M17.3253 43.8164C16.587 44.4316 16.7921 45.6758 17.6808 46.0449C17.9679 46.1543 21.4132 46.1953 30.8195 46.1679L43.589 46.1269L43.9991 45.6621C44.3546 45.2656 44.4093 45.1289 44.3409 44.6367C44.2863 44.2676 44.1359 43.9668 43.9034 43.7754L43.548 43.4609H30.6417H17.7491L17.3253 43.8164Z"
          fill="#233B76"
        />
        <path
          d="M17.2999 51.3769C16.7804 51.9101 16.7531 52.7031 17.2316 53.2637L17.5597 53.6465L26.6378 53.6875C36.1534 53.7285 36.2492 53.7148 36.6593 53.1133C36.9738 52.6758 36.9191 51.8828 36.5363 51.4316L36.2081 51.0488L26.9523 51.0078L17.7101 50.9805L17.2999 51.3769Z"
          fill="#233B76"
        />
        <path
          d="M17.5594 58.7188C16.7801 59.1426 16.6571 60.1816 17.2997 60.8379L17.6962 61.2344H26.8837H36.0712L36.4676 60.8379C37.1239 60.168 36.9872 59.1289 36.1805 58.7051C35.5926 58.4043 18.12 58.4043 17.5594 58.7188Z"
          fill="#233B76"
        />
        <path
          d="M54.0215 54.9316C52.2715 55.3828 51.0684 56.791 50.4941 59.0468L50.3027 59.7988L49.5645 59.8671L48.8398 59.9355L48.6895 60.7831L48.5254 61.6445H49.332C50.125 61.6445 50.125 61.6445 50.125 62.0546C50.125 62.4511 50.0977 62.4648 49.5098 62.4648C48.8398 62.4648 48.9082 62.3828 48.6348 63.6542L48.5117 64.2421H49.373H50.2207L50.4531 65.1581C50.918 67.0449 51.834 68.3574 53.1191 68.9316C54.0625 69.3691 56.0723 69.3554 57.0703 68.9042C58.2734 68.3574 59.1621 67.0996 59.4629 65.4999L59.5586 65.0624H57.9863C57.1113 65.0624 56.4141 65.1171 56.4141 65.1855C56.4141 65.2539 56.25 65.4999 56.0586 65.7324C55.4023 66.5253 54.3359 66.2246 53.8711 65.1171C53.502 64.2558 53.5293 64.2421 55.1699 64.2421H56.6465L56.8105 63.4628C56.8926 63.039 56.9609 62.6425 56.9609 62.5742C56.9609 62.5195 56.168 62.4648 55.1836 62.4648H53.4063V62.0546V61.6581L55.2793 61.6171L57.166 61.5761L57.3164 60.7148L57.4668 59.8671H55.5801C53.7617 59.8671 53.6797 59.8535 53.6797 59.5937C53.6797 59.1835 54.1855 58.2539 54.4863 58.0898C55.1289 57.748 56.1406 58.1171 56.3457 58.7734C56.4277 59.0195 56.5645 59.0468 57.9863 59.0468H59.5586L59.4766 58.5956C59.3398 57.8437 58.6563 56.5175 58.1504 56.0117C57.4258 55.2734 56.5371 54.9042 55.4023 54.8632C54.8691 54.8359 54.2539 54.8632 54.0215 54.9316Z"
          fill="#233B76"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_76_1842">
        <rect width={85} height={85} fill="white" />
      </clipPath>
      <clipPath id="clip1_76_1842">
        <rect width={70} height={70} fill="white" transform="translate(2 6)" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgIcoBill);
export default Memo;
