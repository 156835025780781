import {
  AppBar,
  Box,
  Button,
  Container,
  Link,
  Toolbar,
  Typography,
} from '@mui/material';
import posthog from 'posthog-js';
import { FormattedMessage } from 'react-intl';

import ElevationScroll from '../ElevationScroll';

const NavigationHeader = () => {
  return (
    <ElevationScroll>
      <AppBar sx={{ px: 0 }} color="transparent" component="nav">
        <Container sx={{ height: '100%' }}>
          <Toolbar
            disableGutters
            sx={{ justifyContent: 'space-between', height: '100%' }}
          >
            <Link
              component="button"
              underline="none"
              onClick={() => window.scrollTo(0, 0)}
            >
              <Typography variant="body1" color="global01">
                <b>KINE VAN MOER</b>
              </Typography>
            </Link>
            <Box>
              <Button
                href="#afspraak"
                onClick={() => {
                  posthog.capture('button_appointment_click');
                }}
              >
                <FormattedMessage id="button.appointment" />
              </Button>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </ElevationScroll>
  );
};

export default NavigationHeader;
