import { Button, Link, styled, Typography } from '@mui/material';
import { Container, Stack } from '@mui/system';
import { posthog } from 'posthog-js';
import { FormattedMessage } from 'react-intl';

import { Section } from 'components/@screens';

const Image = styled('img')`
  align-self: center;
  max-width: 100%;
  object-fit: contain;
`;

const IntroHeader = () => {
  return (
    <Section bgcolor="neutral02">
      <Container
        maxWidth="lg"
        sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}
      >
        <Stack gap={3} pt={{ xs: 5, md: 15 }} mb={{ xs: 0, md: 15 }}>
          <Typography variant="subtitle1" component="span">
            <FormattedMessage id="home.header.top_title" />
          </Typography>

          <Typography
            variant="h1"
            color="global01"
            sx={{
              whiteSpace: 'pre-line',
              display: { xs: 'none', sm: 'block' },
            }}
          >
            <FormattedMessage id="home.header.title" />
          </Typography>
          <Typography
            variant="h1"
            color="global01"
            sx={{
              whiteSpace: 'pre-line',
              display: { xs: 'block', sm: 'none' },
            }}
          >
            <FormattedMessage id="home.header.mobile.title" />
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{ maxWidth: 500, whiteSpace: 'pre-line' }}
          >
            <FormattedMessage
              id="home.header.bottom_title"
              values={{
                special: (value) => (
                  <Link href="#specialisaties" underline="hover">
                    <b>{value}</b>
                  </Link>
                ),
                location: (value) => (
                  <Link href="#locatie" underline="hover">
                    <b>{value}</b>
                  </Link>
                ),
              }}
            />
          </Typography>
          <Button
            href="#afspraak"
            sx={{ mr: 'auto' }}
            onClick={() => {
              posthog.capture('button_appointment_click');
            }}
          >
            <FormattedMessage id="button.appointment" />
          </Button>
        </Stack>
        <Image
          alt="Afbeelding van een patiënt in behandeling door een kinesitherapeut"
          src="/assets/img-header.svg"
          width={781}
          height={607}
          sx={{
            pb: { xs: 5, md: 0 },
            width: { xs: '100%', sm: 600 },
            height: { xs: 300, sm: 400 },
          }}
        />
      </Container>
    </Section>
  );
};

export default IntroHeader;
