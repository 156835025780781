import { Grid, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { FormattedMessage, useIntl } from 'react-intl';

import { IcoBill, IcoFamily, IcoSpeech } from 'components/@icons';
import { Section } from 'components/@screens';

import UspItem from './UspItem';

const Usp = () => {
  const intl = useIntl();
  return (
    <Section name="visie">
      <Container
        maxWidth="lg"
        sx={{
          py: 8,
        }}
      >
        <Typography
          variant="h1"
          color="global01"
          sx={{ whiteSpace: 'pre-line' }}
        >
          <FormattedMessage id="home.usp.title" />
        </Typography>
        <Typography variant="body2" sx={{ maxWidth: 700 }} mt={4}>
          <FormattedMessage id="home.usp.body" />
        </Typography>
        <Grid container mt={6} columnSpacing={5} rowGap={8}>
          <Grid item xs={12} md={4}>
            <UspItem
              icon={
                <IcoSpeech
                  aria-label={`Icoon ${intl.formatMessage({
                    id: 'home.usp.2.title',
                  })}`}
                />
              }
              title={<FormattedMessage id="home.usp.2.title" />}
              body={<FormattedMessage id="home.usp.2.body" />}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <UspItem
              icon={
                <IcoBill
                  aria-label={`Icoon ${intl.formatMessage({
                    id: 'home.usp.1.title',
                  })}`}
                />
              }
              title={<FormattedMessage id="home.usp.1.title" />}
              body={<FormattedMessage id="home.usp.1.body" />}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <UspItem
              icon={
                <IcoFamily
                  aria-label={`Icoon ${intl.formatMessage({
                    id: 'home.usp.3.title',
                  })}`}
                />
              }
              title={<FormattedMessage id="home.usp.3.title" />}
              body={<FormattedMessage id="home.usp.3.body" />}
            />
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
};

export default Usp;
