import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoRedEllipse = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 90 91"
    fill="none"
    role="img"
    {...props}
  >
    <path
      d="M0.934688 0.894779C12.6414 1.01228 24.2104 3.43443 34.981 8.02295C45.7516 12.6115 55.513 19.2765 63.7078 27.6375C71.9026 35.9985 78.3704 45.8917 82.7418 56.7522C87.1132 67.6128 89.3027 79.228 89.1852 90.9347L0.0399623 90.04L0.934688 0.894779Z"
      fill="#FF6565"
      fillOpacity={0.2}
    />
  </svg>
);
const Memo = memo(SvgIcoRedEllipse);
export default Memo;
