import { Fade, useScrollTrigger } from '@mui/material';
import React from 'react';

interface ElevationScrollProps {
  children: React.ReactElement;
}

export default function ElevationScroll(props: ElevationScrollProps) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 400,
  });

  return (
    <Fade appear={false} in={trigger}>
      {children}
    </Fade>
  );
}
